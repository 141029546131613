import type { BusinessPartnerGroupAdd, IBusinessPartnerGroup } from 'src/types/crm/businessPartnerGroup.type';
import type { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'crm/business-partner-groups';

export const getBusinessPartnerGroups = async (
  searchCondition: string | undefined,
  page: number,
  pageSize: number,
  orderBy?: string
) => {
  return await http.get<PagedList<IBusinessPartnerGroup>>(`${controller}`, {
    params: {
      searchCondition,
      page,
      pageSize,
      orderBy
    }
  });
};

export const getBusinessPartnerGroup = async (id: number) => {
  return http.get<IBusinessPartnerGroup>(`${controller}/${id}`);
};

export const addBusinessPartnerGroup = async (businessPartnerGroup: BusinessPartnerGroupAdd) => {
  return http.post<IBusinessPartnerGroup>(controller, businessPartnerGroup);
};

export const updateBusinessPartnerGroup = async (id: number, businessPartnerGroup: BusinessPartnerGroupAdd) => {
  return await http.put<IBusinessPartnerGroup[]>(`${controller}/${id}`, businessPartnerGroup);
};

export const deleteBusinessPartnerGroup = async (id: number) => {
  return await http.delete(`${controller}/${id}`);
};

export const getBusinessPartnerGroupDropdown = async () => {
  return await http.get<IBusinessPartnerGroup[]>(`${controller}/dropdown`);
};
