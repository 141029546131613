import type { CustomerTypeAdd, ICustomerType } from 'src/types/crm/customerType.type';
import type { FlatItem } from 'src/types/elements.type';
import type { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'crm/customer-types';

export const getCustomerTypes = async (
  searchCondition: string | undefined,
  page: number,
  pageSize: number,
  orderBy?: string
) => {
  return await http.get<PagedList<ICustomerType>>(`${controller}`, {
    params: {
      searchCondition,
      page,
      pageSize,
      orderBy
    }
  });
};

export const getCustomerType = async (id: number) => {
  return http.get<ICustomerType>(`${controller}/${id}`);
};

export const addCustomerType = async (customerType: CustomerTypeAdd) => {
  return http.post<ICustomerType>(controller, customerType);
};

export const updateCustomerType = async (id: number, customerType: ICustomerType) => {
  return await http.put<ICustomerType[]>(`${controller}/${id}`, customerType);
};

export const deleteCustomerType = async (id: number) => {
  return await http.delete(`${controller}/${id}`);
};

export const getCustomerTypesDropdown = async () => {
  return await http.get<FlatItem[]>(`${controller}/dropdown`);
};
