import { lazy } from 'react';
import { PermissionEn } from 'src/constants/permission.enum';
import salePath from 'src/constants/urlPath/erp/salePath';
import type { RouteType } from 'src/hooks/useRouteElements';
import NewLayout from '../../layouts/NewFeatures/MainLayout';

const Sale = lazy(() => import('src/pages/ERP/Sale'));

const SO = lazy(() => import('src/pages/ERP/Sale/SaleOrder'));
const AddSO = lazy(() => import('src/pages/ERP/Sale/SaleOrder/AddSaleOrder'));

const SQR = lazy(() => import('src/pages/ERP/Sale/SQR'));
const AddSQR = lazy(() => import('src/pages/ERP/Sale/SQR/AddSQR'));

const SQ = lazy(() => import('src/pages/ERP/Sale/SaleQuotation'));
const AddSQ = lazy(() => import('src/pages/ERP/Sale/SaleQuotation/AddSaleQuotation'));
const CompareSQ = lazy(() => import('src/pages/ERP/Sale/SaleQuotation/CompareSaleQuotation'));

const Opportunity = lazy(() => import('src/pages/ERP/Sale/Opportunity'));
const OpportunityForm = lazy(() => import('src/pages/ERP/Sale/Opportunity/OpportunityForm'));

const WorkAssign = lazy(() => import('src/pages/ERP/Planning/Kpi/WorkAssign'));
const WorkAssignAdd = lazy(() => import('src/pages/ERP/Planning/Kpi/AddWorkAssign'));
const WorkAssignDetail = lazy(() => import('src/pages/ERP/Planning/Kpi/WorkAssignDetail'));
const WorkPlan = lazy(() => import('src/pages/ERP/Planning/Kpi/WorkPlan'));
const WorkPlanAdd = lazy(() => import('src/pages/ERP/Planning/Kpi/AddWorkPlan'));
const WorkPlanDetail = lazy(() => import('src/pages/ERP/Planning/Kpi/WorkPlanDetail'));

const SaleFormCategory = lazy(() => import('src/pages/ERP/Sale/SaleFormCategory'));
const Form = lazy(() => import('src/pages/Ticket/Form'));
const FormAdd = lazy(() => import('src/pages/Ticket/Form/AddForm'));
const FormDetail = lazy(() => import('src/pages/Ticket/FormDetail'));
const Project = lazy(() => import('src/pages/ERP/Sale/Project'));

const saleRoutes: RouteType[] = [
  {
    path: salePath.sale,
    Layout: NewLayout,
    Component: Sale
  },

  // Sale quotation sp - Đơn báo giá cho sale support
  {
    path: salePath.saleQuotationSp,
    Layout: NewLayout,
    Component: SQ,
    checkPath: salePath.saleQuotationSp,
    permission: PermissionEn.read
  },
  {
    path: salePath.saleQuotationSpAdd,
    Layout: NewLayout,
    Component: AddSQ,
    checkPath: salePath.saleQuotationSp,
    permission: PermissionEn.create
  },
  {
    path: `${salePath.saleQuotationSpEdit}/:id`,
    Layout: NewLayout,
    Component: AddSQ,
    checkPath: salePath.saleQuotationSp,
    permission: PermissionEn.update
  },
  {
    path: `${salePath.saleQuotationSpDetail}/:id`,
    Layout: NewLayout,
    Component: AddSQ,
    checkPath: salePath.saleQuotationSp,
    permission: PermissionEn.viewDetail
  },

  // Sale quotation sm - Đơn báo giá cho sale man
  {
    path: salePath.saleQuotationSm,
    Layout: NewLayout,
    Component: SQ,
    checkPath: salePath.saleQuotationSm,
    permission: PermissionEn.read
  },
  {
    path: salePath.saleQuotationSmAdd,
    Layout: NewLayout,
    Component: AddSQ,
    checkPath: salePath.saleQuotationSm,
    permission: PermissionEn.create
  },
  {
    path: `${salePath.saleQuotationSmEdit}/:id`,
    Layout: NewLayout,
    Component: AddSQ,
    checkPath: salePath.saleQuotationSm,
    permission: PermissionEn.update
  },
  {
    path: `${salePath.saleQuotationSmDetail}/:id`,
    Layout: NewLayout,
    Component: AddSQ,
    checkPath: salePath.saleQuotationSm,
    permission: PermissionEn.viewDetail
  },
  {
    path: `${salePath.saleQuotationSmCompare}/:sqrId`,
    Layout: NewLayout,
    Component: CompareSQ,
    checkPath: salePath.saleQuotationSm,
    permission: PermissionEn.viewDetail
  },
  // SQR - Yeu cầu báo giá
  {
    path: salePath.sqr,
    Layout: NewLayout,
    Component: SQR,
    checkPath: salePath.sqr,
    permission: PermissionEn.read
  },
  {
    path: salePath.sqrAdd,
    Layout: NewLayout,
    Component: AddSQR,
    checkPath: salePath.sqr,
    permission: PermissionEn.create
  },
  {
    path: `${salePath.sqrEdit}/:id`,
    Layout: NewLayout,
    Component: AddSQR,
    checkPath: salePath.sqr,
    permission: PermissionEn.update
  },
  {
    path: `${salePath.sqrDetail}/:id`,
    Layout: NewLayout,
    Component: AddSQR,
    checkPath: salePath.sqr,
    permission: PermissionEn.viewDetail
  },
  // Sale order - Đơn bán hàng
  {
    path: salePath.saleOrder,
    Layout: NewLayout,
    Component: SO,
    checkPath: salePath.saleOrder,
    permission: PermissionEn.read
  },
  {
    path: salePath.saleOrderAdd,
    Layout: NewLayout,
    Component: AddSO,
    checkPath: salePath.saleOrder,
    permission: PermissionEn.create
  },
  {
    path: `${salePath.saleOrderEdit}/:id`,
    Layout: NewLayout,
    Component: AddSO,
    checkPath: salePath.saleOrder,
    permission: PermissionEn.update
  },
  {
    path: `${salePath.saleOrderDetail}/:id`,
    Layout: NewLayout,
    Component: AddSO,
    checkPath: salePath.saleOrder,
    permission: PermissionEn.viewDetail
  },
  // Opportunity - Cơ hội kinh doanh
  {
    path: salePath.opportunity,
    Layout: NewLayout,
    Component: Opportunity,
    checkPath: salePath.opportunity,
    permission: PermissionEn.read
  },
  {
    path: salePath.opportunityAdd,
    Layout: NewLayout,
    Component: OpportunityForm,
    checkPath: salePath.opportunity,
    permission: PermissionEn.create
  },
  {
    path: `${salePath.opportunityEdit}/:id`,
    Layout: NewLayout,
    Component: OpportunityForm,
    checkPath: salePath.opportunity,
    permission: PermissionEn.update
  },
  {
    path: `${salePath.opportunityDetail}/:id`,
    Layout: NewLayout,
    Component: OpportunityForm,
    checkPath: salePath.opportunity,
    permission: PermissionEn.viewDetail
  },

  // Work assign - Giao việc bán hàng
  // Work assign
  {
    path: salePath.saleWorkAssign,
    Layout: NewLayout,
    Component: WorkAssign,
    permission: PermissionEn.read
  },
  {
    path: salePath.saleWorkAssignAdd,
    Layout: NewLayout,
    Component: WorkAssignAdd,
    permission: PermissionEn.create
  },
  {
    path: `${salePath.saleWorkAssignDetail}/:id`,
    Layout: NewLayout,
    Component: WorkAssignDetail,
    permission: PermissionEn.viewDetail
  },
  {
    path: `${salePath.saleWorkAssignEdit}/:id`,
    Layout: NewLayout,
    Component: WorkAssignAdd,
    permission: PermissionEn.update
  },
  // Work plan
  {
    path: salePath.saleWorkPlan,
    Layout: NewLayout,
    Component: WorkPlan,
    permission: PermissionEn.read
  },
  {
    path: salePath.saleWorkPlanAdd,
    Layout: NewLayout,
    Component: WorkPlanAdd,
    permission: PermissionEn.create
  },
  {
    path: `${salePath.saleWorkPlanDetail}/:id`,
    Layout: NewLayout,
    Component: WorkPlanDetail,
    permission: PermissionEn.viewDetail
  },
  {
    path: `${salePath.saleWorkPlanEdit}/:id`,
    Layout: NewLayout,
    Component: WorkPlanAdd,
    permission: PermissionEn.update
  },
  // Form
  {
    path: salePath.saleForm,
    Layout: NewLayout,
    Component: Form,
    permission: PermissionEn.read
  },
  {
    path: salePath.saleFormAdd,
    Layout: NewLayout,
    Component: FormAdd,
    permission: PermissionEn.create
  },
  {
    path: `${salePath.saleFormDetail}/:id`,
    Layout: NewLayout,
    Component: FormDetail,
    permission: PermissionEn.viewDetail
  },
  {
    path: `${salePath.saleFormEdit}/:id`,
    Layout: NewLayout,
    Component: FormAdd,
    permission: PermissionEn.update
  },
  // Sale Form Category
  {
    path: salePath.saleFormCategory,
    Layout: NewLayout,
    Component: SaleFormCategory,
    permission: PermissionEn.read
  },
  // Sale Form Category
  {
    path: salePath.saleProject,
    Layout: NewLayout,
    Component: Project,
    permission: PermissionEn.read
  }
];

export default saleRoutes;

// {
//   path: urlPath.salePr.location,
//   Layout: NewLayout,
//   Component: PurchaseRequest,
//   checkPath: urlPath.salePr.location,
//   permission: PermissionEn.read
// },
