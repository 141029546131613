import type { workAssignTypeEn } from 'src/constants/workAssignType.enum';
import type { IWorkAssign, WorkAssignAdd, WorkAssignShort } from 'src/types/erp/workAssign.type';
import type { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'helpdesk/assign-tasks';

export const getWorkAssignsDropdown = async (type?: workAssignTypeEn) => {
  return await http.get<WorkAssignShort[]>(`${controller}/dropdown`, {
    params: { type }
  });
};

export const getWorkAssigns = async (type: workAssignTypeEn, page: number, pageSize: number, orderBy?: string) => {
  return await http.get<PagedList<IWorkAssign>>(controller, {
    params: {
      type,
      page,
      pageSize,
      orderBy
    }
  });
};

export const getWorkAssign = async (id: number) => {
  return await http.get<IWorkAssign>(`${controller}/${id}`);
};

export const addWorkAssign = async (workAssign: WorkAssignAdd) => {
  return await http.post(controller, workAssign);
};

export const updateWorkAssign = async (id: number, workAssign: WorkAssignAdd) => {
  return await http.put(`${controller}/${id}`, workAssign);
};

export const deleteWorkAssign = async (id: number) => {
  return await http.delete(`${controller}/${id}`);
};
