import { lazy } from 'react';
import accountingPath from 'src/constants/urlPath/erp/accountingPath';
import type { RouteType } from 'src/hooks/useRouteElements';
import NewLayout from '../../layouts/NewFeatures/MainLayout';

const Incoterm = lazy(() => import('src/pages/ERP/Accounting/Incoterm'));
const IncotermForm = lazy(() => import('src/pages/ERP/Accounting/Incoterm/IncotermForm'));
const PaymentMethod = lazy(() => import('src/pages/ERP/Accounting/PaymentMethod'));
const PaymentMethodForm = lazy(() => import('src/pages/ERP/Accounting/PaymentMethod/PaymentMethodForm'));
const PaymentDocument = lazy(() => import('src/pages/ERP/Accounting/PaymentDocument'));
const PaymentDocumentForm = lazy(() => import('src/pages/ERP/Accounting/PaymentDocument/PaymentDocumentForm'));
const MainShippingMethod = lazy(() => import('src/pages/ERP/Accounting/MainShippingMethod'));
const MainShippingMethodForm = lazy(() => import('src/pages/ERP/Accounting/MainShippingMethod/MainShippingMethodForm'));
const ShippingStage = lazy(() => import('src/pages/ERP/Accounting/ShippingStage'));
const ShippingStageForm = lazy(() => import('src/pages/ERP/Accounting/ShippingStage/ShippingStageForm'));
const PaymentDocumentDetailRequirement = lazy(
  () => import('src/pages/ERP/Accounting/PaymentDocumentDetailRequirement')
);
const PaymentDocumentDetailRequirementForm = lazy(
  () => import('src/pages/ERP/Accounting/PaymentDocumentDetailRequirement/PaymentDocumentDetailRequirementForm')
);
const AssetAllocation = lazy(() => import('src/pages/ERP/Accounting/AssetAllocation'));
const AssetAllocationForm = lazy(() => import('src/pages/ERP/Accounting/AssetAllocation/AssetAllocationForm'));

const accountingRoutes: RouteType[] = [
  {
    path: accountingPath.incoterm,
    Layout: NewLayout,
    Component: Incoterm
  },
  {
    path: accountingPath.incotermAdd,
    Layout: NewLayout,
    Component: IncotermForm
  },
  {
    path: `${accountingPath.incotermEdit}/:id`,
    Layout: NewLayout,
    Component: IncotermForm
  },
  {
    path: `${accountingPath.incotermDetail}/:id`,
    Layout: NewLayout,
    Component: IncotermForm
  },
  {
    path: accountingPath.paymentMethod,
    Layout: NewLayout,
    Component: PaymentMethod
  },
  {
    path: accountingPath.paymentMethodAdd,
    Layout: NewLayout,
    Component: PaymentMethodForm
  },
  {
    path: `${accountingPath.paymentMethodEdit}/:id`,
    Layout: NewLayout,
    Component: PaymentMethodForm
  },
  {
    path: `${accountingPath.paymentMethodDetail}/:id`,
    Layout: NewLayout,
    Component: PaymentMethodForm
  },
  {
    path: accountingPath.paymentDocument,
    Layout: NewLayout,
    Component: PaymentDocument
  },
  {
    path: accountingPath.paymentDocumentAdd,
    Layout: NewLayout,
    Component: PaymentDocumentForm
  },
  {
    path: `${accountingPath.paymentDocumentEdit}/:id`,
    Layout: NewLayout,
    Component: PaymentDocumentForm
  },
  {
    path: `${accountingPath.paymentDocumentDetail}/:id`,
    Layout: NewLayout,
    Component: PaymentDocumentForm
  },
  {
    path: accountingPath.mainShippingMethod,
    Layout: NewLayout,
    Component: MainShippingMethod
  },
  {
    path: accountingPath.mainShippingMethodAdd,
    Layout: NewLayout,
    Component: MainShippingMethodForm
  },
  {
    path: `${accountingPath.mainShippingMethodEdit}/:id`,
    Layout: NewLayout,
    Component: MainShippingMethodForm
  },
  {
    path: `${accountingPath.mainShippingMethodDetail}/:id`,
    Layout: NewLayout,
    Component: MainShippingMethodForm
  },
  {
    path: accountingPath.shippingStage,
    Layout: NewLayout,
    Component: ShippingStage
  },
  {
    path: accountingPath.shippingStageAdd,
    Layout: NewLayout,
    Component: ShippingStageForm
  },
  {
    path: `${accountingPath.shippingStageEdit}/:id`,
    Layout: NewLayout,
    Component: ShippingStageForm
  },
  {
    path: `${accountingPath.shippingStageDetail}/:id`,
    Layout: NewLayout,
    Component: ShippingStageForm
  },
  {
    path: accountingPath.paymentDocumentDetailRequirement,
    Layout: NewLayout,
    Component: PaymentDocumentDetailRequirement
  },
  {
    path: accountingPath.paymentDocumentDetailRequirementAdd,
    Layout: NewLayout,
    Component: PaymentDocumentDetailRequirementForm
  },
  {
    path: `${accountingPath.paymentDocumentDetailRequirementEdit}/:id`,
    Layout: NewLayout,
    Component: PaymentDocumentDetailRequirementForm
  },
  {
    path: `${accountingPath.paymentDocumentDetailRequirementDetail}/:id`,
    Layout: NewLayout,
    Component: PaymentDocumentDetailRequirementForm
  },
  //asset-allocation
  {
    path: accountingPath.assetAllocation,
    Layout: NewLayout,
    Component: AssetAllocation
  },
  {
    path: `${accountingPath.assetAllocationDetail}/:id`,
    Layout: NewLayout,
    Component: AssetAllocationForm
  },
  {
    path: accountingPath.assetAllocationAdd,
    Layout: NewLayout,
    Component: AssetAllocationForm
  }
  // {
  //   path: `${accountingPath.assetAllocationEdit}/:id`,
  //   Layout: NewLayout,
  //   Component: AssetAllocationForm
  // },
  // {
  //   path: `${accountingPath.assetAllocationDetail}/:id`,
  //   Layout: NewLayout,
  //   Component: AssetAllocationForm
  // }
];

export default accountingRoutes;
