import type { HierarchicalItem } from 'src/types/elements.type';
import type { IProductionProcessGroup, ProductionProcessGroupAdd } from 'src/types/erp/rnd/productionProcessGroup.type';
import { http } from 'src/utils/http';

const controller = 'pm/production-process-groups';

export const getProductionProcessGroups = async (page: number, pageSize: number, orderBy?: string) => {
  return await http.get<IProductionProcessGroup[]>(controller, {
    params: {
      page,
      pageSize,
      orderBy
    }
  });
};

export const getProductionProcessGroup = async (id: number) => {
  return await http.get<IProductionProcessGroup>(`${controller}/${id}`);
};

export const getProductionProcessGroupDropdown = async (isFilter?: boolean) => {
  return await http.get<HierarchicalItem[]>(`${controller}/dropdown`, {
    params: {
      isFilter
    }
  });
};
export const getProductionProcessGroupByCode = async (code: string) => {
  return await http.get<IProductionProcessGroup>(`${controller}/code/${code}`);
};
export const addProductionProcessGroup = async (data: ProductionProcessGroupAdd) => {
  return await http.post(controller, data);
};
export const updateProductionProcessGroup = async (id: number, data: ProductionProcessGroupAdd) => {
  return await http.put(`${controller}/${id}`, data);
};

export const deleteProductionProcessGroup = async (dataId: number) => {
  return await http.delete(`${controller}/${dataId}`);
};
