import { CategoryEnum } from 'src/constants/item_master_data/itemMasterData.enum';
import type { FlatItem } from 'src/types/elements.type';
import type { ITicketFile } from 'src/types/helpdesk/ticket.type';
import type { IModel, ModelAdd, ModelImportAdd, ModelWithItemMasterData } from 'src/types/item_master_data/model.type';
import type { IModelMapping, ModelMappingAdd } from 'src/types/item_master_data/modelMapping.type';
import type { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'pm/models';

export const getModelsDropdown = async (categoryId: CategoryEnum) => {
  return await http.get<FlatItem[]>(`${controller}/dropdown`, {
    params: {
      categoryId
    }
  });
};
export const getModelsByItemShort = async (itemId: number) => {
  return await http.get<ModelWithItemMasterData[]>(`${controller}/${itemId}/short`);
};

export const getModels = async (
  // sequenceStatus: string,
  searchCondition: string | undefined,
  page: number,
  pageSize: number,
  itemTypeId?: number,
  orderBy?: string,
  itemMasterDataId?: number,
  itemGroupId?: number
) => {
  return await http.get<PagedList<IModel>>(controller, {
    params: {
      // sequenceStatus,
      searchCondition,
      itemTypeId,
      itemMasterDataId,
      itemGroupId,
      page,
      pageSize,
      orderBy
    }
  });
};

export const getModel = async (id: number) => {
  return await http.get<IModel>(`${controller}/${id}`);
};

export const exportModels = async (
  searchCondition: string | undefined,
  itemTypeId: number | undefined,
  itemGroupId: number | undefined,
  itemMasterDataId: number | undefined
) =>
  await http.get<IModel[]>(`${controller}/export`, {
    params: {
      searchCondition,
      itemTypeId,
      itemGroupId,
      itemMasterDataId
    }
  });

export const addModel = async (model: ModelAdd) => {
  return await http.post(controller, model);
};

export const addModels = async (models: ModelImportAdd) => {
  return await http.post(`${controller}/add-list`, models);
};

export const updateModel = async (id: number, model: ModelAdd) => {
  return await http.put(`${controller}/${id}`, model);
};

export const uploadModelFile = async <T>(formData: FormData, param: T) => {
  return await http.post<ITicketFile[]>(`${controller}/upload/${param}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const deleteModel = async (id: number) => {
  return await http.delete(`${controller}/${id}`);
};

// Mapping
export const getModelMappings = async (categoryId?: CategoryEnum) => {
  return await http.get<IModelMapping[]>(`${controller}/mappings/get-all`, {
    params: {
      categoryId
    }
  });
};

export const getModelMapping = async (id: number) => {
  return await http.get<IModelMapping>(`${controller}/mappings/${id}`);
};

export const addModelMappings = async (modelMappings: ModelMappingAdd[]) => {
  return await http.post(`${controller}/mappings/add-list`, modelMappings);
};
