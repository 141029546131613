import { lazy } from 'react';
import deliveryPath from 'src/constants/urlPath/erp/deliveryPath';
import type { RouteType } from 'src/hooks/useRouteElements';
import NewLayout from '../../layouts/NewFeatures/MainLayout';

const DeliveryOrder = lazy(() => import('src/pages/ERP/ContractExecution/Delivery/DeliveryOrder'));
// const DeliveryOrder = lazy(() => import('src/pages/ERP/ContractExecution/Production/DeliveryOrder'));
const AddDeliveryOrder = lazy(() => import('src/pages/ERP/ContractExecution/Delivery/DeliveryOrder/AddDeliveryOrder'));

// const ProductionRequest = lazy(() => import('src/pages/ERP/ContractExecution/Production/ProductionRequest'));
// const AddProductionRequest = lazy(
//   () => import('src/pages/ERP/ContractExecution/Production/ProductionRequest/AddProductionRequest')
// );

// const AcceptanceReport = lazy(() => import('src/pages/ERP/ContractExecution/Production/AcceptanceReport'));

// const AcceptanceMinute = lazy(() => import('src/pages/ERP/ContractExecution/Production/AcceptanceMinute'));
// const AcceptanceMinuteForm = lazy(
//   () => import('src/pages/ERP/ContractExecution/Production/AcceptanceMinute/AcceptanceMinuteForm')
// );

// const AddRawMatBom = lazy(() => import('src/pages/ERP/ContractExecution/Production/RawMatBom/AddRawMatBom'));
// const AddLaborBom = lazy(() => import('src/pages/ERP/ContractExecution/Production/LaborBom/AddLaborBom'));
// const AddEquipmentBom = lazy(() => import('src/pages/ERP/ContractExecution/Production/EquipmentBom/AddEquipmentBom'));

const deliveryRoutes: RouteType[] = [
  // Delivery order - DS yêu cầu giao hàng
  {
    path: deliveryPath.deliveryOrder,
    Layout: NewLayout,
    Component: DeliveryOrder
  },
  {
    path: deliveryPath.deliveryOrderAdd,
    Layout: NewLayout,
    Component: AddDeliveryOrder
  },
  {
    path: `${deliveryPath.deliveryOrderEdit}/:id`,
    Layout: NewLayout,
    Component: AddDeliveryOrder
  },
  {
    path: `${deliveryPath.deliveryOrderDetail}/:id`,
    Layout: NewLayout,
    Component: AddDeliveryOrder
  }

  // Delivery request - Lệnh giao hàng
  //   {
  //     path: deliveryPath.productionRequest,
  //     Layout: NewLayout,
  //     Component: ProductionRequest
  //   },
  //   {
  //     path: deliveryPath.productionRequestAdd,
  //     Layout: NewLayout,
  //     Component: AddProductionRequest
  //   },
  //   {
  //     path: `${deliveryPath.productionRequestEdit}/:code`,
  //     Layout: NewLayout,
  //     Component: AddProductionRequest
  //   },
  //   {
  //     path: `${deliveryPath.productionRequestDetailCode}/:code`,
  //     Layout: NewLayout,
  //     Component: AddProductionRequest
  //   },
];

export default deliveryRoutes;
