import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import { type ReactNode, useContext } from 'react';
import { getEmployeesDropdown } from 'src/apis/master_data/employee.api';
import { AppContext } from 'src/contexts/app.context';
import type { SelectProps } from 'src/types/elements.type';

type Props = SelectProps & {
  exclusionEmployee?: number;
  departmentId?: number;
  placeholder?: ReactNode;
  size?: SizeType;
  disabled?: boolean;
  hasUserName?: boolean;
};

export default function EmployeeSelect({
  exclusionEmployee,
  style,
  value,
  allowClear = true,
  onChange,
  departmentId,
  disabled,
  placeholder,
  size = 'middle',
  hasUserName,
  returnValueType = 'id'
}: Props) {
  const { isUserAdmin } = useContext(AppContext);
  const deptId = isUserAdmin ? undefined : departmentId;

  const { data, isFetching } = useQuery({
    queryKey: ['employeesDropdown', deptId, hasUserName],
    queryFn: () => getEmployeesDropdown(deptId, undefined, undefined, hasUserName),
    staleTime: 60 * 1000
  });

  return (
    <Select
      allowClear={allowClear}
      value={isFetching ? undefined : value}
      style={style}
      placeholder={placeholder}
      onChange={onChange}
      disabled={disabled}
      options={data?.data
        .filter((item) => item.id !== exclusionEmployee)
        .map((item) => ({
          value: returnValueType === 'object' ? JSON.stringify(item) : item[returnValueType],
          label: `${item.code} - ${item.fullName}`,
          data: item
        }))}
      size={size}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={isFetching}
    />
  );
}
