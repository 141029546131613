import { lazy } from 'react';
import { PermissionEn } from 'src/constants/permission.enum';
import itemMasterDataPath from 'src/constants/urlPath/erp/itemMasterDataPath';
import type { RouteType } from 'src/hooks/useRouteElements';
import NewLayout from '../../layouts/NewFeatures/MainLayout';

const ItemMasterData = lazy(() => import('src/pages/ERP/ItemMasterData'));

const Item = lazy(() => import('src/pages/ERP/ItemMasterData/Item'));
const ItemForm = lazy(() => import('src/pages/ERP/ItemMasterData/Item/ItemForm'));

const ItemType = lazy(() => import('src/pages/ERP/ItemMasterData/ItemType'));
const ItemTypeForm = lazy(() => import('src/pages/ERP/ItemMasterData/ItemType/ItemTypeForm'));

const ItemGroup = lazy(() => import('src/pages/ERP/ItemMasterData/ItemGroup'));
const ItemGroupForm = lazy(() => import('src/pages/ERP/ItemMasterData/ItemGroup/ItemGroupForm'));

const Model = lazy(() => import('src/pages/ERP/ItemMasterData/Model'));
const ModelForm = lazy(() => import('src/pages/ERP/ItemMasterData/Model/ModelForm'));

const Design = lazy(() => import('src/pages/ERP/ItemMasterData/Design'));
const DesignForm = lazy(() => import('src/pages/ERP/ItemMasterData/Design/DesignForm'));

const Specification = lazy(() => import('src/pages/ERP/ItemMasterData/Specification'));

const ItemTechModel = lazy(() => import('src/pages/ERP/ItemMasterData/ItemTechnicalSpecification'));

const ItemTechSpec = lazy(() => import('src/pages/ERP/ItemMasterData/DimensionalStandard'));

const AccountingItem = lazy(() => import('src/pages/ERP/ItemMasterData/AccountingItem'));

const AccountingAccountType = lazy(() => import('src/pages/MasterData/AccountingAccountType'));

const AccountingObjectType = lazy(() => import('src/pages/MasterData/AccountingObjectType'));

const GeneralAccountingAccount = lazy(() => import('src/pages/MasterData/GeneralAccountingAccount'));

const Process = lazy(() => import('src/pages/ERP/ItemMasterData/Process'));
const ProcessForm = lazy(() => import('src/pages/ERP/ItemMasterData/Process/ProcessForm'));

const ContractualUnitPrice = lazy(() => import('src/pages/ERP/ItemMasterData/ContractualUnitPrice'));
const ContractualUnitPriceForm = lazy(
  () => import('src/pages/ERP/ItemMasterData/ContractualUnitPrice/ContractualUnitPriceForm')
);
const ProductionService = lazy(() => import('src/pages/ERP/ItemMasterData/ProductionService'));

const PS = lazy(() => import('src/pages/ERP/ItemMasterData/PS'));

const itemMasterDataRoutes: RouteType[] = [
  // Index page
  {
    path: itemMasterDataPath.itemMasterData,
    Layout: NewLayout,
    Component: ItemMasterData,
    checkPath: itemMasterDataPath.itemMasterData,
    permission: PermissionEn.read
  },
  // ITEM
  {
    path: itemMasterDataPath.item,
    Layout: NewLayout,
    Component: Item,
    checkPath: itemMasterDataPath.item,
    permission: PermissionEn.read
  },
  {
    path: itemMasterDataPath.itemAdd,
    Layout: NewLayout,
    Component: ItemForm,
    checkPath: itemMasterDataPath.item,
    permission: PermissionEn.create
  },
  {
    path: `${itemMasterDataPath.itemEdit}/:id`,
    Layout: NewLayout,

    Component: ItemForm,
    checkPath: itemMasterDataPath.item,
    permission: PermissionEn.update
  },
  {
    path: `${itemMasterDataPath.itemDetail}/:id`,
    Layout: NewLayout,
    Component: ItemForm,
    checkPath: itemMasterDataPath.item,
    permission: PermissionEn.viewDetail
  },
  // LOẠI ITEM
  {
    path: itemMasterDataPath.itemType,
    Layout: NewLayout,
    Component: ItemType,
    checkPath: itemMasterDataPath.itemType,
    permission: PermissionEn.read
  },
  {
    path: itemMasterDataPath.itemTypeAdd,
    Layout: NewLayout,

    Component: ItemTypeForm,
    checkPath: itemMasterDataPath.itemType,
    permission: PermissionEn.create
  },
  {
    path: `${itemMasterDataPath.itemTypeEdit}/:id`,
    Layout: NewLayout,

    Component: ItemTypeForm,
    checkPath: itemMasterDataPath.itemType,
    permission: PermissionEn.update
  },
  {
    path: `${itemMasterDataPath.itemTypeDetail}/:id`,
    Layout: NewLayout,
    Component: ItemTypeForm,
    checkPath: itemMasterDataPath.itemType,
    permission: PermissionEn.viewDetail
  },
  // NHÓM ITEM
  {
    path: itemMasterDataPath.itemGroup,
    Layout: NewLayout,
    Component: ItemGroup,
    checkPath: itemMasterDataPath.itemGroup,
    permission: PermissionEn.read
  },
  {
    path: itemMasterDataPath.itemGroupAdd,
    Layout: NewLayout,

    Component: ItemGroupForm,
    checkPath: itemMasterDataPath.itemGroup,
    permission: PermissionEn.create
  },
  {
    path: `${itemMasterDataPath.itemGroupEdit}/:id`,
    Layout: NewLayout,

    Component: ItemGroupForm,
    checkPath: itemMasterDataPath.itemGroup,
    permission: PermissionEn.update
  },
  {
    path: `${itemMasterDataPath.itemGroupDetail}/:id`,
    Layout: NewLayout,
    Component: ItemGroupForm,
    checkPath: itemMasterDataPath.itemGroup,
    permission: PermissionEn.viewDetail
  },
  // MODEL
  {
    path: itemMasterDataPath.model,
    Layout: NewLayout,
    Component: Model,
    checkPath: itemMasterDataPath.model,
    permission: PermissionEn.read
  },
  {
    path: itemMasterDataPath.modelAdd,
    Layout: NewLayout,

    Component: ModelForm,
    checkPath: itemMasterDataPath.model,
    permission: PermissionEn.create
  },
  {
    path: `${itemMasterDataPath.modelEdit}/:id`,
    Layout: NewLayout,

    Component: ModelForm,
    checkPath: itemMasterDataPath.model,
    permission: PermissionEn.update
  },
  {
    path: `${itemMasterDataPath.modelDetail}/:id`,
    Layout: NewLayout,
    Component: ModelForm,
    checkPath: itemMasterDataPath.model,
    permission: PermissionEn.viewDetail
  },
  // BẢN THIẾT KẾ
  {
    path: itemMasterDataPath.design,
    Layout: NewLayout,
    Component: Design,
    checkPath: itemMasterDataPath.design,
    permission: PermissionEn.read
  },
  {
    path: itemMasterDataPath.designAdd,
    Layout: NewLayout,
    Component: DesignForm,
    checkPath: itemMasterDataPath.design,
    permission: PermissionEn.create
  },
  {
    path: `${itemMasterDataPath.designEdit}/:id`,
    Layout: NewLayout,
    Component: DesignForm,
    checkPath: itemMasterDataPath.design,
    permission: PermissionEn.update
  },
  {
    path: `${itemMasterDataPath.designDetail}/:id`,
    Layout: NewLayout,
    Component: DesignForm,
    checkPath: itemMasterDataPath.design,
    permission: PermissionEn.viewDetail
  },
  // THÔNG SỐ KỸ THUẬT
  {
    path: itemMasterDataPath.specification,
    Layout: NewLayout,
    Component: Specification,
    checkPath: itemMasterDataPath.specification,
    permission: PermissionEn.read
  },
  // BỘ THÔNG SỐ KỸ THUẬT MODEL
  {
    path: itemMasterDataPath.itemTechModel,
    Layout: NewLayout,
    Component: ItemTechModel,
    checkPath: itemMasterDataPath.itemTechModel,
    permission: PermissionEn.read
  },
  // // BỘ THÔNG SỐ KỸ QUY CÁCH
  {
    path: itemMasterDataPath.itemTechSpec,
    Layout: NewLayout,
    Component: ItemTechSpec,
    checkPath: itemMasterDataPath.itemTechSpec,
    permission: PermissionEn.read
  },
  // DANH MỤC TÀI KHOẢN KỂ TOÁN
  {
    path: itemMasterDataPath.accountingItem,
    Layout: NewLayout,
    Component: AccountingItem,
    checkPath: itemMasterDataPath.accountingItem,
    permission: PermissionEn.read
  },
  // LOẠI TÀI KHOẢN KẾ TOÁN
  {
    path: itemMasterDataPath.accountingAccountType,
    Layout: NewLayout,
    Component: AccountingAccountType,
    checkPath: itemMasterDataPath.accountingAccountType,
    permission: PermissionEn.read
  },
  // LOẠI ĐỐI TƯỢNG KẾ TOÁN TỔNG HỢP
  {
    path: itemMasterDataPath.accountingObjectType,
    Layout: NewLayout,
    Component: AccountingObjectType,
    checkPath: itemMasterDataPath.accountingObjectType,
    permission: PermissionEn.read
  },
  // TÀI KHOẢN KẾ TOÁN TỔNG HỢP
  {
    path: itemMasterDataPath.generalAccountingAccount,
    Layout: NewLayout,
    Component: GeneralAccountingAccount,
    checkPath: itemMasterDataPath.generalAccountingAccount,
    permission: PermissionEn.read
  },
  // QUY TRÌNH
  {
    path: itemMasterDataPath.process,
    Layout: NewLayout,
    Component: Process,
    checkPath: itemMasterDataPath.process,
    permission: PermissionEn.read
  },
  {
    path: itemMasterDataPath.processAdd,
    Layout: NewLayout,
    Component: ProcessForm,
    checkPath: itemMasterDataPath.process,
    permission: PermissionEn.create
  },
  {
    path: `${itemMasterDataPath.processEdit}/:id`,
    Layout: NewLayout,
    Component: ProcessForm,
    checkPath: itemMasterDataPath.process,
    permission: PermissionEn.update
  },
  {
    path: `${itemMasterDataPath.processDetail}/:id`,
    Layout: NewLayout,
    Component: ProcessForm,
    checkPath: itemMasterDataPath.process,
    permission: PermissionEn.viewDetail
  },
  // ĐƠN GIÁ KHOÁN
  {
    path: itemMasterDataPath.contractualUnitPrice,
    Layout: NewLayout,
    Component: ContractualUnitPrice,
    checkPath: itemMasterDataPath.contractualUnitPrice,
    permission: PermissionEn.read
  },
  {
    path: itemMasterDataPath.contractualUnitPriceAdd,
    Layout: NewLayout,
    Component: ContractualUnitPriceForm,
    checkPath: itemMasterDataPath.contractualUnitPrice,
    permission: PermissionEn.create
  },
  {
    path: `${itemMasterDataPath.contractualUnitPriceEdit}/:id`,
    Layout: NewLayout,
    Component: ContractualUnitPriceForm,
    checkPath: itemMasterDataPath.contractualUnitPrice,
    permission: PermissionEn.update
  },
  {
    path: `${itemMasterDataPath.contractualUnitPriceDetail}/:id`,
    Layout: NewLayout,
    Component: ContractualUnitPriceForm,
    checkPath: itemMasterDataPath.contractualUnitPrice,
    permission: PermissionEn.viewDetail
  },
  // PS
  {
    path: itemMasterDataPath.ps,
    Layout: NewLayout,
    Component: PS,
    checkPath: itemMasterDataPath.ps,
    permission: PermissionEn.read
  },
  // DANH MỤC DỊCH VỤ SẢN XUẤT
  {
    path: itemMasterDataPath.productionService,
    Layout: NewLayout,
    Component: ProductionService,
    checkPath: itemMasterDataPath.productionService,
    permission: PermissionEn.read
  }
];

export default itemMasterDataRoutes;
