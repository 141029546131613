import { CRUDPathSegmentEn } from 'src/constants/newFeatures/crudPathSegment.enum';

const inventoryPath = {
  inventory: '/erp/inventory',

  // MRP - Kiểm tra tồn kho
  inventoryMrp: '/erp/inventory/mrp',

  // GRPOR - Phiếu yêu cầu nhập kho
  inventoryGrpor: '/erp/inventory/grpor',
  inventoryGrporDetail: '/erp/inventory/grpor' + CRUDPathSegmentEn.DETAIL,
  inventoryGrporDetailCode: '/erp/inventory/grpor' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  inventoryGrporEdit: '/erp/inventory/grpor' + CRUDPathSegmentEn.EDIT,

  // ITR - Phiếu yêu cầu chuyển kho
  itr: '/erp/inventory/itr',
  itrDetail: '/erp/inventory/itr' + CRUDPathSegmentEn.DETAIL,
  itrDetailCode: '/erp/inventory/itr' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  itrAdd: '/erp/inventory/itr' + CRUDPathSegmentEn.ADD,
  itrEdit: '/erp/inventory/itr' + CRUDPathSegmentEn.EDIT,

  // IT - Phiếu chuyển kho
  it: '/erp/inventory/it',
  itDetail: '/erp/inventory/it' + CRUDPathSegmentEn.DETAIL,
  itDetailCode: '/erp/inventory/it' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  itAdd: '/erp/inventory/it' + CRUDPathSegmentEn.ADD,
  itEdit: '/erp/inventory/it' + CRUDPathSegmentEn.EDIT
};
export default inventoryPath;
