import { CRUDPathSegmentEn } from 'src/constants/newFeatures/crudPathSegment.enum';

const salePath = {
  sale: '/erp/sale',

  // SQR - Phiếu yêu cầu báo giá
  sqr: '/erp/sale/sqr',
  sqrDetail: '/erp/sale/sqr' + CRUDPathSegmentEn.DETAIL,
  sqrAdd: '/erp/sale/sqr' + CRUDPathSegmentEn.ADD,
  sqrEdit: '/erp/sale/sqr' + CRUDPathSegmentEn.EDIT,

  // Sale quotation sp - Phiếu báo giá cho sale support
  saleQuotationSp: '/erp/sale/sale-quotation-sp',
  saleQuotationSpDetail: '/erp/sale/sale-quotation-sp' + CRUDPathSegmentEn.DETAIL,
  saleQuotationSpAdd: '/erp/sale/sale-quotation-sp' + CRUDPathSegmentEn.ADD,
  saleQuotationSpEdit: '/erp/sale/sale-quotation-sp' + CRUDPathSegmentEn.EDIT,

  // Sale quotation sm - Phiếu báo giá cho sale man
  saleQuotationSm: '/erp/sale/sale-quotation-sm',
  saleQuotationSmDetail: '/erp/sale/sale-quotation-sm' + CRUDPathSegmentEn.DETAIL,
  saleQuotationSmAdd: '/erp/sale/sale-quotation-sm' + CRUDPathSegmentEn.ADD,
  saleQuotationSmEdit: '/erp/sale/sale-quotation-sm' + CRUDPathSegmentEn.EDIT,
  saleQuotationSmCompare: '/erp/sale/sale-quotation-sm/compare',

  // Sale order - Phiếu bán hàng
  saleOrder: '/erp/sale/sale-order',
  saleOrderDetail: '/erp/sale/sale-order' + CRUDPathSegmentEn.DETAIL,
  saleOrderAdd: '/erp/sale/sale-order' + CRUDPathSegmentEn.ADD,
  saleOrderEdit: '/erp/sale/sale-order' + CRUDPathSegmentEn.EDIT,

  // Opportunity
  opportunity: '/erp/sale/opportunity',
  opportunityDetail: '/erp/sale/opportunity' + CRUDPathSegmentEn.DETAIL,
  opportunityAdd: '/erp/sale/opportunity' + CRUDPathSegmentEn.ADD,
  opportunityEdit: '/erp/sale/opportunity' + CRUDPathSegmentEn.EDIT,

  // Work assign
  saleWorkAssign: '/erp/sale/work-assign',
  saleWorkAssignDetail: '/erp/sale/work-assign' + CRUDPathSegmentEn.DETAIL,
  saleWorkAssignAdd: '/erp/sale/work-assign' + CRUDPathSegmentEn.ADD,
  saleWorkAssignEdit: '/erp/sale/work-assign' + CRUDPathSegmentEn.EDIT,

  // Work Plan
  saleWorkPlan: '/erp/sale/work-plan',
  saleWorkPlanDetail: '/erp/sale/work-plan' + CRUDPathSegmentEn.DETAIL,
  saleWorkPlanAdd: '/erp/sale/work-plan' + CRUDPathSegmentEn.ADD,
  saleWorkPlanEdit: '/erp/sale/work-plan' + CRUDPathSegmentEn.EDIT,

  // Form
  saleForm: '/erp/sale/form',
  saleFormDetail: '/erp/sale/form' + CRUDPathSegmentEn.DETAIL,
  saleFormAdd: '/erp/sale/form' + CRUDPathSegmentEn.ADD,
  saleFormEdit: '/erp/sale/form' + CRUDPathSegmentEn.EDIT,

  // Sale Form Category
  saleFormCategory: '/erp/sale/sale-form-category',

  // Sale Form Category
  saleProject: '/erp/sale/project'
};

export default salePath;
