import { useQuery } from '@tanstack/react-query';
import { Select, type SelectProps } from 'antd';
import { getUnitOfMeasuresShort } from 'src/apis/master_data/unitOfMeasure.api';

export default function UnitOfMeasureSelect({
  value,
  onChange,
  style,
  allowClear = true,
  placeholder,
  disabled
}: SelectProps) {
  const unitOfMeasuresDropdownQuery = useQuery({
    queryKey: ['unitOfMeasuresShort'],
    queryFn: () => getUnitOfMeasuresShort(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      disabled={disabled}
      allowClear={allowClear}
      value={unitOfMeasuresDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={unitOfMeasuresDropdownQuery.data?.data.map((data) => ({
        value: data.id,
        label:
          data.unitSymbol2 || data.unitSymbol ? `${data.unitSymbol2 || data.unitSymbol} - ${data.name}` : data.name,
        data: data
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      placeholder={placeholder}
      loading={unitOfMeasuresDropdownQuery.isFetching}
    />
  );
}
