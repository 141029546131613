import { lazy } from 'react';
import { PermissionEn } from 'src/constants/permission.enum';
import crmMasterDataPath from 'src/constants/urlPath/crm/masterDataPath';
import type { RouteType } from 'src/hooks/useRouteElements';
import NewLayout from '../../layouts/NewFeatures/MainLayout';

const MasterData = lazy(() => import('src/pages/CRM/MasterData'));
const CustomerType = lazy(() => import('src/pages/CRM/MasterData/CustomerType'));
const BusinessPartnerGroup = lazy(() => import('src/pages/CRM/MasterData/BusinessPartnerGroup'));

const crmMasterDataRoutes: RouteType[] = [
  {
    path: crmMasterDataPath.masterData,
    Layout: NewLayout,
    Component: MasterData,
    checkPath: crmMasterDataPath.masterData,
    permission: PermissionEn.read
  },
  {
    path: crmMasterDataPath.crmCustomerType,
    Layout: NewLayout,
    Component: CustomerType,
    checkPath: crmMasterDataPath.crmCustomerType,
    permission: PermissionEn.read
  },
  {
    path: crmMasterDataPath.crmBusinessPartnerGroup,
    Layout: NewLayout,
    Component: BusinessPartnerGroup,
    checkPath: crmMasterDataPath.crmBusinessPartnerGroup,
    permission: PermissionEn.read
  }
];

export default crmMasterDataRoutes;
