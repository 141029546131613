import { CRUDPathSegmentEn } from '../../newFeatures/crudPathSegment.enum';

const deliveryPath = {
  deliveryManagement: '/erp/contract-execution/delivery',
  // Delivery order - DS yêu cầu giao hàng
  deliveryOrder: '/erp/contract-execution/delivery/delivery-order',
  deliveryOrderDetail: '/erp/contract-execution/delivery/delivery-order' + CRUDPathSegmentEn.DETAIL,
  // deliveryOrderDetailCode: '/erp/contract-execution/delivery/delivery-order' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  deliveryOrderAdd: '/erp/contract-execution/delivery/delivery-order' + CRUDPathSegmentEn.ADD,
  deliveryOrderEdit: '/erp/contract-execution/delivery/delivery-order' + CRUDPathSegmentEn.EDIT,

  // Delivery request - Lệnh giao hàng
  deliveryRequest: '/erp/contract-execution/delivery/delivery-request',
  deliveryRequestDetail: '/erp/contract-execution/delivery/delivery-request' + CRUDPathSegmentEn.DETAIL,
  deliveryRequestDetailCode: '/erp/contract-execution/delivery/delivery-request' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  deliveryRequestAdd: '/erp/contract-execution/delivery/delivery-request' + CRUDPathSegmentEn.ADD,
  deliveryRequestEdit: '/erp/contract-execution/delivery/delivery-request' + CRUDPathSegmentEn.EDIT
};
export default deliveryPath;
