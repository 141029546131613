import { useQuery } from '@tanstack/react-query';
import { Select, type SelectProps } from 'antd';
import { getModelsByItemShort } from 'src/apis/item_master_data/model.api';

type Props = SelectProps & {
  // category?: number;
  itemId?: number;
  valueType?: 'id' | 'code';
  filterWidth?: number;
  excludeIds?: number[];
};

export default function ModelSelect({
  value,
  onChange,
  style,
  allowClear = true,
  placeholder,
  disabled,
  // category,
  itemId,
  valueType = 'id',
  filterWidth = 158,
  excludeIds = [],
  ...props
}: Props) {
  const modelsDropdownQuery = useQuery({
    queryKey: ['modelsDropdown', itemId],
    queryFn: () => getModelsByItemShort(Number(itemId)),
    staleTime: 60 * 1000,
    enabled: !!itemId
  });

  // const modelsDropdownQuery = useQuery({
  //   queryKey: ['modelsDropdown', category, itemId],
  //   queryFn: () => (!!itemId ? getModelsByItemShort(Number(itemId)) : getModelsDropdown(Number(category))),
  //   staleTime: 60 * 1000,
  //   enabled: !!category || !!itemId
  // });

  return (
    <Select
      disabled={disabled}
      allowClear={allowClear}
      value={modelsDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={modelsDropdownQuery?.data?.data
        ?.filter((x) => !excludeIds.includes(x.id))
        ?.map((item) => ({
          value: item[valueType],
          label: `${item.code} - ${item.name}`,
          data: item
        }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      placeholder={placeholder}
      loading={modelsDropdownQuery.isFetching}
      {...props}
    />
  );
}
