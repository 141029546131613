import { useQuery } from '@tanstack/react-query';
import { TreeSelect, type SelectProps } from 'antd';
import { useEffect, useState } from 'react';
import { getProductionProcessGroupDropdown } from 'src/apis/erp/rnd/productionProcessGroup.api';
import type { TreeItem } from 'src/types/elements.type';
import { buildHierarchySelect } from 'src/utils/collection';

type Props = SelectProps & {
  valueType?: 'id' | 'code';
};

export default function ProductionProcessGroupTreeSelect({
  value,
  onChange,
  style,
  allowClear = true,
  valueType = 'id',
  ...props
}: Props) {
  const [data, setData] = useState<TreeItem[] | undefined>(undefined);

  const processGroupsDropdownQuery = useQuery({
    queryKey: ['processGroupsDropdown'],
    queryFn: () => getProductionProcessGroupDropdown(),
    staleTime: 60 * 1000
  });

  useEffect(() => {
    const result = buildHierarchySelect(processGroupsDropdownQuery.data?.data);

    setData(result);
  }, [processGroupsDropdownQuery.data?.data]);

  return (
    <TreeSelect
      {...props}
      treeLine={true}
      value={processGroupsDropdownQuery.isFetching ? undefined : value}
      showSearch
      placement='bottomLeft'
      style={style}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      treeNodeFilterProp='value'
      allowClear={allowClear}
      onChange={onChange}
      treeData={data}
      loading={processGroupsDropdownQuery.isFetching}
      filterTreeNode={(inputValue, treeNode) => {
        return treeNode.title?.toString().toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()) ?? false;
      }}
    />
  );
}
