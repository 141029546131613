const crmMasterDataPath = {
  masterData: '/crm/master-data',

  // TYPE
  crmCustomerType: '/crm/master-data/customer-type',

  // GROUP
  crmBusinessPartnerGroup: '/crm/master-data/business-partner-group'
};
export default crmMasterDataPath;
