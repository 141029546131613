import type { FlatItem } from 'src/types/elements.type';
import type { IUnitOfMeasure, UnitOfMeasureAdd } from 'src/types/master_data/unitOfMeasure.type';
import type { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'master-data/unit-of-measures';

export const getUnitOfMeasures = async (
  searchCondition: string | undefined,
  unitType: number | undefined,
  page: number,
  pageSize: number,
  orderBy?: string
) => {
  return await http.get<PagedList<IUnitOfMeasure>>(`${controller}`, {
    params: {
      searchCondition,
      unitType,
      page,
      pageSize,
      orderBy
    }
  });
};

export const getUnitOfMeasure = async (id: number) => {
  return http.get<IUnitOfMeasure>(`${controller}/${id}`);
};

export const addUnitOfMeasure = async (unitOfMeasure: UnitOfMeasureAdd) => {
  return http.post<UnitOfMeasureAdd>(controller, unitOfMeasure);
};

export const updateUnitOfMeasure = async (id: number, unitOfMeasure: IUnitOfMeasure) => {
  return await http.put<IUnitOfMeasure[]>(`${controller}/${id}`, unitOfMeasure);
};

export const deleteUnitOfMeasure = async (id: number) => {
  return await http.delete(`${controller}/${id}`);
};

export const getUnitOfMeasuresDropdown = async () => {
  return await http.get<FlatItem[]>(`${controller}/dropdown`);
};
export const getUnitOfMeasuresShort = async (unitType?: number) => {
  return await http.get<IUnitOfMeasure[]>(`${controller}/short`, {
    params: { unitType }
  }); // Lấy full chứ ko lấy short như cái tên đâu
};
