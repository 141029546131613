import { useMutation } from '@tanstack/react-query';
import { Skeleton } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { getResourceFile } from 'src/apis/master_data/resource.api';
import Icon from 'src/components/v2/Icon';
type Props = { filePath: string; size?: number; style?: React.CSSProperties; className?: string };

function ResourceThumbnail({ filePath, size = 40, style, className }: Props) {
  const [src, setSrc] = useState<string | null>(null);

  // Mutation để lấy hình ảnh từ API
  const resourceThumbnailMutation = useMutation({
    mutationFn: (filePath: string) => getResourceFile(filePath),
    onSuccess: async (data, variables) => {
      try {
        // Lưu vào cache
        const cache = await caches.open('resource-thumbnails');
        const blob = new Blob([data.data]); // Tạo Blob từ dữ liệu API
        const response = new Response(blob);
        await cache.put(variables, response);

        // Hiển thị hình ảnh
        setSrc(URL.createObjectURL(blob));
      } catch (error) {
        console.error('Error caching image:', error);
      }
    },
    onError: (error) => {
      console.error('Error fetching image:', error);
    }
  });

  // Sử dụng useRef để giữ ổn định mutation
  const mutationRef = useRef(resourceThumbnailMutation);

  useEffect(() => {
    let isMounted = true; // Để tránh setState khi component unmount

    const fetchImage = async () => {
      if (!filePath || !isMounted) return;

      try {
        const cache = await caches.open('resource-thumbnails');
        const cachedResponse = await cache.match(filePath);

        if (cachedResponse) {
          // Lấy từ cache
          const blob = await cachedResponse.blob();
          setSrc(URL.createObjectURL(blob));
        } else {
          // Fetch qua mutation
          mutationRef.current.mutate(filePath);
        }
      } catch (error) {
        console.error('Error loading resource:', error);
      }
    };

    fetchImage();

    return () => {
      isMounted = false; // Cleanup khi component unmount
    };
  }, [filePath]);

  return resourceThumbnailMutation.isLoading ? (
    <Skeleton.Avatar
      active={resourceThumbnailMutation.isLoading}
      style={{ width: size, height: size, borderRadius: 8, display: 'flex' }}
      shape={'square'}
    />
  ) : (
    <Icon style={style} className={`thumbnail-icon ${className}`} size={size} url={src} />
  );
}

export default ResourceThumbnail;
