import { CRUDPathSegmentEn } from 'src/constants/newFeatures/crudPathSegment.enum';

const itemMasterDataPath = {
  itemMasterData: '/erp/item-master-data',

  // ITEM
  item: '/erp/item-master-data/item',
  itemAdd: '/erp/item-master-data/item' + CRUDPathSegmentEn.ADD,
  itemEdit: '/erp/item-master-data/item' + CRUDPathSegmentEn.EDIT,
  itemDetail: '/erp/item-master-data/item' + CRUDPathSegmentEn.DETAIL,

  // LOẠI ITEM
  itemType: '/erp/item-master-data/item-type',
  itemTypeAdd: '/erp/item-master-data/item-type' + CRUDPathSegmentEn.ADD,
  itemTypeEdit: '/erp/item-master-data/item-type' + CRUDPathSegmentEn.EDIT,
  itemTypeDetail: '/erp/item-master-data/item-type' + CRUDPathSegmentEn.DETAIL,

  // NHÓM ITEM
  itemGroup: '/erp/item-master-data/item-group',
  itemGroupAdd: '/erp/item-master-data/item-group' + CRUDPathSegmentEn.ADD,
  itemGroupEdit: '/erp/item-master-data/item-group' + CRUDPathSegmentEn.EDIT,
  itemGroupDetail: '/erp/item-master-data/item-group' + CRUDPathSegmentEn.DETAIL,

  // MODEL
  model: '/erp/item-master-data/model',
  modelAdd: '/erp/item-master-data/model' + CRUDPathSegmentEn.ADD,
  modelEdit: '/erp/item-master-data/model' + CRUDPathSegmentEn.EDIT,
  modelDetail: '/erp/item-master-data/model' + CRUDPathSegmentEn.DETAIL,

  // BẢN THIẾT KẾ
  design: '/erp/item-master-data/design',
  designAdd: '/erp/item-master-data/design' + CRUDPathSegmentEn.ADD,
  designEdit: '/erp/item-master-data/design' + CRUDPathSegmentEn.EDIT,
  designDetail: '/erp/item-master-data/design' + CRUDPathSegmentEn.DETAIL,

  // THÔNG SỐ KỸ THUẬT
  specification: '/erp/item-master-data/specification',
  specificationAdd: '/erp/item-master-data/specification' + CRUDPathSegmentEn.ADD,
  specificationEdit: '/erp/item-master-data/specification' + CRUDPathSegmentEn.EDIT,
  specificationDetail: '/erp/item-master-data/specification' + CRUDPathSegmentEn.DETAIL,

  // BỘ THÔNG SỐ KỸ THUẬT MODEL
  itemTechModel: '/erp/item-master-data/item-tech-model',
  itemTechModelAdd: '/erp/item-master-data/item-tech-model' + CRUDPathSegmentEn.ADD,
  itemTechModelEdit: '/erp/item-master-data/item-tech-model' + CRUDPathSegmentEn.EDIT,
  itemTechModelDetail: '/erp/item-master-data/item-tech-model' + CRUDPathSegmentEn.DETAIL,

  // BỘ THÔNG SỐ KỸ THUẬT QUY CÁCH
  itemTechSpec: '/erp/item-master-data/item-tech-spec',
  itemTechSpecAdd: '/erp/item-master-data/item-tech-spec' + CRUDPathSegmentEn.ADD,
  itemTechSpecEdit: '/erp/item-master-data/item-tech-spec' + CRUDPathSegmentEn.EDIT,
  itemTechSpecDetail: '/erp/item-master-data/item-tech-spec' + CRUDPathSegmentEn.DETAIL,

  // DANH MỤC TÀI KHOẢN KỂ TOÁN
  accountingItem: '/erp/item-master-data/accounting-item',
  accountingItemAdd: '/erp/item-master-data/accounting-item' + CRUDPathSegmentEn.ADD,
  accountingItemEdit: '/erp/item-master-data/accounting-item' + CRUDPathSegmentEn.EDIT,
  accountingItemDetail: '/erp/item-master-data/accounting-item' + CRUDPathSegmentEn.DETAIL,

  // LOẠI TÀI KHOẢN KẾ TOÁN
  accountingAccountType: '/erp/item-master-data/accounting-account-type',
  accountingAccountTypeAdd: '/erp/item-master-data/accounting-account-type' + CRUDPathSegmentEn.ADD,
  accountingAccountTypeEdit: '/erp/item-master-data/accounting-account-type' + CRUDPathSegmentEn.EDIT,
  accountingAccountTypeDetail: '/erp/item-master-data/accounting-account-type' + CRUDPathSegmentEn.DETAIL,

  // LOẠI ĐỐI TƯỢNG KẾ TOÁN TỔNG HỢP
  accountingObjectType: '/erp/item-master-data/accounting-object-type',
  accountingObjectTypeAdd: '/erp/item-master-data/accounting-object-type' + CRUDPathSegmentEn.ADD,
  accountingObjectTypeEdit: '/erp/item-master-data/accounting-object-type' + CRUDPathSegmentEn.EDIT,
  accountingObjectTypeDetail: '/erp/item-master-data/accounting-object-type' + CRUDPathSegmentEn.DETAIL,

  // TÀI KHOẢN KẾ TOÁN TỔNG HỢP
  generalAccountingAccount: '/erp/item-master-data/general-accounting-account',
  generalAccountingAccountAdd: '/erp/item-master-data/general-accounting-account' + CRUDPathSegmentEn.ADD,
  generalAccountingAccountEdit: '/erp/item-master-data/general-accounting-account' + CRUDPathSegmentEn.EDIT,
  generalAccountingAccountDetail: '/erp/item-master-data/general-accounting-account' + CRUDPathSegmentEn.DETAIL,

  // QUY TRÌNH
  process: '/erp/item-master-data/process',
  processAdd: '/erp/item-master-data/process' + CRUDPathSegmentEn.ADD,
  processEdit: '/erp/item-master-data/process' + CRUDPathSegmentEn.EDIT,
  processDetail: '/erp/item-master-data/process' + CRUDPathSegmentEn.DETAIL,

  // ĐƠN GIÁ KHOÁN
  contractualUnitPrice: '/erp/item-master-data/contractual-unit-price',
  contractualUnitPriceAdd: '/erp/item-master-data/contractual-unit-price' + CRUDPathSegmentEn.ADD,
  contractualUnitPriceEdit: '/erp/item-master-data/contractual-unit-price' + CRUDPathSegmentEn.EDIT,
  contractualUnitPriceDetail: '/erp/item-master-data/contractual-unit-price' + CRUDPathSegmentEn.DETAIL,
  // PS
  ps: '/erp/item-master-data/ps',
  psAdd: '/erp/item-master-data/ps' + CRUDPathSegmentEn.ADD,
  psEdit: '/erp/item-master-data/ps' + CRUDPathSegmentEn.EDIT,
  psDetail: '/erp/item-master-data/ps' + CRUDPathSegmentEn.DETAIL,

  // DANH MỤC DỊCH VỤ SẢN XUẤT
  productionService: '/erp/item-master-data/production-service'
};

export default itemMasterDataPath;
