import { lazy } from 'react';
import { PermissionEn } from 'src/constants/permission.enum';
import rndPath from 'src/constants/urlPath/erp/rndPath';
import type { RouteType } from 'src/hooks/useRouteElements';
import NewLayout from '../../layouts/NewFeatures/MainLayout';

const Rnd = lazy(() => import('src/pages/Rnd'));
const RawMatPmr = lazy(() => import('src/pages/Rnd/RawMatPmr'));
const AddRawMatPmr = lazy(() => import('src/pages/Rnd/RawMatPmr/AddRawMatPmr'));

const LaborPmr = lazy(() => import('src/pages/Rnd/LaborPmr'));
const AddLaborPmr = lazy(() => import('src/pages/Rnd/LaborPmr/AddLaborPmr'));

const EquipmentPmr = lazy(() => import('src/pages/Rnd/EquipmentPmr'));
const AddEquipmentPmr = lazy(() => import('src/pages/Rnd/EquipmentPmr/AddEquipmentPmr'));

const ProductionProcessGroup = lazy(() => import('src/pages/Rnd/ProductionProcessGroup'));

const ProductionProcess = lazy(() => import('src/pages/Rnd/ProductionProcess'));
const AddProductionProcess = lazy(() => import('src/pages/Rnd/ProductionProcess/AddProductionProcess'));

const Item = lazy(() => import('src/pages/ERP/ItemMasterData/Item'));
const ItemForm = lazy(() => import('src/pages/ERP/ItemMasterData/Item/ItemForm'));

const Design = lazy(() => import('src/pages/ERP/ItemMasterData/Design'));
const DesignForm = lazy(() => import('src/pages/ERP/ItemMasterData/Design/DesignForm'));

const Model = lazy(() => import('src/pages/ERP/ItemMasterData/Model'));
const AddModel = lazy(() => import('src/pages/ERP/ItemMasterData/Model/ModelForm'));

const PS = lazy(() => import('src/pages/ERP/ItemMasterData/PS'));
const ProductionJobTask = lazy(() => import('src/pages/Rnd/ProductionJobTask'));
const AddProductionJobTask = lazy(() => import('src/pages/Rnd/ProductionJobTask/AddProductionJobTask'));

const rndRoutes: RouteType[] = [
  {
    path: rndPath.rnd,
    Layout: NewLayout,
    Component: Rnd,
    checkPath: rndPath.rnd,
    permission: PermissionEn.read
  },
  // Raw Material PMR - PMR Nguyên vật liệu
  {
    path: rndPath.rawMatPmr,
    Layout: NewLayout,
    Component: RawMatPmr,
    checkPath: rndPath.rawMatPmr,
    permission: PermissionEn.read
  },
  {
    path: rndPath.rawMatPmrAdd,
    Layout: NewLayout,
    Component: AddRawMatPmr,
    checkPath: rndPath.rawMatPmr,
    permission: PermissionEn.create
  },
  {
    path: `${rndPath.rawMatPmrEdit}/:id`,
    Layout: NewLayout,
    Component: AddRawMatPmr,
    checkPath: rndPath.rawMatPmr,
    permission: PermissionEn.update
  },
  {
    path: `${rndPath.rawMatPmrDetail}/:id`,
    Layout: NewLayout,
    Component: AddRawMatPmr,
    checkPath: rndPath.rawMatPmr,
    permission: PermissionEn.viewDetail
  },

  // Labor PMR - PMR Nhân công
  {
    path: rndPath.laborPmr,
    Layout: NewLayout,
    Component: LaborPmr,
    checkPath: rndPath.laborPmr,
    permission: PermissionEn.read
  },
  {
    path: rndPath.laborPmrAdd,
    Layout: NewLayout,
    Component: AddLaborPmr,
    checkPath: rndPath.laborPmr,
    permission: PermissionEn.create
  },
  {
    path: `${rndPath.laborPmrEdit}/:id`,
    Layout: NewLayout,
    Component: AddLaborPmr,
    checkPath: rndPath.laborPmr,
    permission: PermissionEn.update
  },
  {
    path: `${rndPath.laborPmrDetail}/:id`,
    Layout: NewLayout,
    Component: AddLaborPmr,
    checkPath: rndPath.laborPmr,
    permission: PermissionEn.viewDetail
  },

  // Equipment PMR - PMR công cụ dụng cụ
  {
    path: rndPath.equipmentPmr,
    Layout: NewLayout,
    Component: EquipmentPmr,
    checkPath: rndPath.equipmentPmr,
    permission: PermissionEn.read
  },
  {
    path: rndPath.equipmentPmrAdd,
    Layout: NewLayout,
    Component: AddEquipmentPmr,
    checkPath: rndPath.equipmentPmr,
    permission: PermissionEn.create
  },
  {
    path: `${rndPath.equipmentPmrEdit}/:id`,
    Layout: NewLayout,
    Component: AddEquipmentPmr,
    checkPath: rndPath.equipmentPmr,
    permission: PermissionEn.update
  },
  {
    path: `${rndPath.equipmentPmrDetail}/:id`,
    Layout: NewLayout,
    Component: AddEquipmentPmr,
    checkPath: rndPath.equipmentPmr,
    permission: PermissionEn.viewDetail
  },

  // Production Process - Nhóm Quy trình sản xuất
  {
    path: rndPath.rndProductionProcessGroup,
    Layout: NewLayout,
    Component: ProductionProcessGroup,
    checkPath: rndPath.rndProductionProcessGroup,
    permission: PermissionEn.read
  },

  // Production Process - Quy trình sản xuất
  {
    path: rndPath.rndProductionProcess,
    Layout: NewLayout,
    Component: ProductionProcess,
    checkPath: rndPath.rndProductionProcess,
    permission: PermissionEn.read
  },
  {
    path: rndPath.rndProductionProcessAdd,
    Layout: NewLayout,
    Component: AddProductionProcess,
    checkPath: rndPath.rndProductionProcess,
    permission: PermissionEn.create
  },
  {
    path: `${rndPath.rndProductionProcessEdit}/:code`,
    Layout: NewLayout,
    Component: AddProductionProcess,
    checkPath: rndPath.rndProductionProcess,
    permission: PermissionEn.update
  },
  {
    path: `${rndPath.rndProductionProcessDetailCode}/:code`,
    Layout: NewLayout,
    Component: AddProductionProcess,
    checkPath: rndPath.rndProductionProcess,
    permission: PermissionEn.viewDetail
  },
  // Item master data Thành phẩm
  {
    path: rndPath.itemProductRnd,
    Layout: NewLayout,
    Component: Item,
    checkPath: rndPath.itemProductRnd,
    permission: PermissionEn.read
  },
  {
    path: rndPath.itemProductRndAdd,
    Layout: NewLayout,
    Component: ItemForm,
    checkPath: rndPath.itemProductRnd,
    permission: PermissionEn.create
  },
  {
    path: `${rndPath.itemProductRndEdit}/:id`,
    Layout: NewLayout,
    Component: ItemForm,
    checkPath: rndPath.itemProductRnd,
    permission: PermissionEn.update
  },
  {
    path: `${rndPath.itemProductRndDetail}/:id`,
    Layout: NewLayout,
    Component: ItemForm,
    checkPath: rndPath.itemProductRnd,
    permission: PermissionEn.viewDetail
  },
  // Item master data NVL
  {
    path: rndPath.itemMaterialRnd,
    Layout: NewLayout,
    Component: Item,
    checkPath: rndPath.itemMaterialRnd,
    permission: PermissionEn.read
  },
  {
    path: rndPath.itemMaterialRndAdd,
    Layout: NewLayout,
    Component: ItemForm,
    checkPath: rndPath.itemMaterialRnd,
    permission: PermissionEn.create
  },
  {
    path: `${rndPath.itemMaterialRndEdit}/:id`,
    Layout: NewLayout,
    Component: ItemForm,
    checkPath: rndPath.itemMaterialRnd,
    permission: PermissionEn.update
  },
  {
    path: `${rndPath.itemMaterialRndDetail}/:id`,
    Layout: NewLayout,
    Component: ItemForm,
    checkPath: rndPath.itemMaterialRnd,
    permission: PermissionEn.viewDetail
  },
  // Bảng thiết kế
  {
    path: rndPath.designRnd,
    Layout: NewLayout,
    Component: Design,
    checkPath: rndPath.designRnd,
    permission: PermissionEn.read
  },
  {
    path: `${rndPath.designRndDetail}/:id`,
    Layout: NewLayout,
    Component: DesignForm,
    checkPath: rndPath.designRnd,
    permission: PermissionEn.viewDetail
  },
  {
    path: rndPath.designRndAdd,
    Layout: NewLayout,
    Component: DesignForm,
    checkPath: rndPath.designRnd,
    permission: PermissionEn.create
  },
  {
    path: `${rndPath.designRndEdit}/:id`,
    Layout: NewLayout,
    Component: DesignForm,
    checkPath: rndPath.designRnd,
    permission: PermissionEn.update
  },
  // Model
  {
    path: rndPath.modelRnd,
    Layout: NewLayout,
    Component: Model,
    checkPath: rndPath.modelRnd,
    permission: PermissionEn.read
  },
  {
    path: rndPath.modelRndAdd,
    Layout: NewLayout,
    Component: AddModel,
    checkPath: rndPath.modelRnd,
    permission: PermissionEn.create
  },
  {
    path: `${rndPath.modelRndEdit}/:id`,
    Layout: NewLayout,
    Component: AddModel,
    checkPath: rndPath.modelRnd,
    permission: PermissionEn.update
  },
  {
    path: `${rndPath.modelRndDetail}/:id`,
    Layout: NewLayout,
    Component: AddModel,
    checkPath: rndPath.modelRnd,
    permission: PermissionEn.viewDetail
  },
  // Model
  {
    path: rndPath.modelRnd,
    Layout: NewLayout,
    Component: Model,
    checkPath: rndPath.modelRnd,
    permission: PermissionEn.read
  },
  {
    path: rndPath.modelRndAdd,
    Layout: NewLayout,
    Component: AddModel,
    checkPath: rndPath.modelRnd,
    permission: PermissionEn.create
  },
  {
    path: `${rndPath.modelRndEdit}/:id`,
    Layout: NewLayout,
    Component: AddModel,
    checkPath: rndPath.modelRnd,
    permission: PermissionEn.update
  },
  {
    path: `${rndPath.modelRndDetail}/:id`,
    Layout: NewLayout,
    Component: AddModel,
    checkPath: rndPath.modelRnd,
    permission: PermissionEn.viewDetail
  },
  // PS
  {
    path: rndPath.psRnd,
    Layout: NewLayout,
    Component: PS,
    checkPath: rndPath.psRnd,
    permission: PermissionEn.read
  },
  // ProductionJobTask
  {
    path: rndPath.ProductionJobTaskRnd,
    Layout: NewLayout,
    Component: ProductionJobTask,
    checkPath: rndPath.ProductionJobTaskRnd,
    permission: PermissionEn.read
  },
  {
    path: rndPath.ProductionJobTaskRndAdd,
    Layout: NewLayout,
    Component: AddProductionJobTask,
    checkPath: rndPath.ProductionJobTaskRndAdd,
    permission: PermissionEn.read
  }
];

export default rndRoutes;
